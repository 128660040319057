
import VueDatepickerLocal from 'vue-datepicker-local'
export default {
	name: 'reportQuery',
	data() {
		return {
			first: '2018-1-1',
			last: '2018-10-1',
			list: '',
			search: '',
			total: 0,
			pageSize: 0,
			currentPage: 1,
			searchers: false,
		}
	},

	components: { VueDatepickerLocal },

	created() {
		this.token = this.getCookie('token')
		this.getReportQuery()
	},
	methods: {
		getReportQuery() {
			var _this = this;
			this.searchers = false;
			_this.$http.post('/API/PLAT/platPageAAA', {
				name: "",
				page: _this.currentPage
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.list = response.data.data.objArray;
					_this.total = response.data.data.total;
					_this.pageSize = response.data.data.pageSize;
					_this.currentPage = response.data.data.currentPage;
				}
			})
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage;
			if (this.searchers) {
				this.searchBtn()
			} else {
				this.getReportQuery()
			}
		},
		searchBtn() {
			var _this = this;
			this.searchers = true;
			_this.$http.post('/API/PLAT/platPageAAA', {
				name: _this.search,
				page: _this.currentPage
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.list = response.data.data.objArray;
					_this.total = response.data.data.total;
					_this.pageSize = response.data.data.pageSize;
					_this.currentPage = response.data.data.currentPage;
				}
			})
		},
		toPar(id) {
			this.$router.push({ name: 'plCrcParticulars', params: { id: id } });
			//				this.$store.commit('getId',id)
		},
		toProject(id) {
			this.$router.push({ name: 'projectParticulars', params: { id: id } })
			//				this.$store.commit('getId',id)
		},
		//导出
		export2Excel() {
			require.ensure([], () => {
				const { export_json_to_excel } = require('../../vendor/Export2Excel');
				const tHeader = ['科室', '项目简称', '适应症', '姓名', 'SMO', '介入日期', '退出日期'];
				const filterVal = ['departmentName', 'shortName', 'disName', 'crcName', 'smoName', 'andTime', 'outTime'];
				const list = this.list;
				const data = this.formatJson(filterVal, list);
				export_json_to_excel(tHeader, data, '查询列表');
			})
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map(v => filterVal.map(j => v[j]))
		}
	}
}
